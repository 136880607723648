var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative"},[_c('input',_vm._g(_vm._b({staticClass:"block w-full rounded-md border-0 px-2 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus-visible:outline-none sm:text-sm sm:leading-6",class:[
      _vm.errors?.length
        ? 'pr-10 ring-red-300 focus:ring-red-500'
        : 'ring-gray-300 focus:ring-primary-500',
      _setup.slots.trailing ? 'pr-10' : '',
    ],attrs:{"id":_vm.id,"aria-invalid":!!_vm.errors?.length},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),{
      ..._vm.$listeners,
      input: _setup.handleInput,
    })),_vm._v(" "),_c('div',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"},[(_setup.slots.trailing)?_vm._t("trailing"):(_vm.errors?.length)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("\n      $vuetify.icons.alert\n    ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }